import { Body, Button, Dialog, Divider, LayoutVerticalSpacer } from '@madpaws/design-system';
import { useRouter } from 'next/router';
import React from 'react';

import { SIGN_IN_ROUTE } from '~/common/constants/auth';
import { DOM_CONTAINER_ID } from '~/components/constants';

import styles from './LoginRequiredDialog.module.css';

import type { ReactElement } from 'react';

type Props = {
  isOpen: boolean;
  onDialogClose: () => void;
  title: string;
};

export const LoginRequiredDialog = ({ isOpen, onDialogClose, title }: Props): ReactElement => {
  const router = useRouter();

  const onClick = (): void => {
    router.push(`${SIGN_IN_ROUTE}?redirectUrl=${router.asPath}`);
  };

  return (
    <Dialog
      domContainerId={DOM_CONTAINER_ID}
      heading="Create a free account or log in"
      isOpen={isOpen}
      onRequestClose={onDialogClose}
    >
      <div className={styles.content}>
        <Body size="default">{title}</Body>
      </div>
      <LayoutVerticalSpacer>
        <Divider />
      </LayoutVerticalSpacer>
      <div className={styles.buttonContainer}>
        <Button label="Cancel" onClick={onDialogClose} variant="tertiary" />
        <Button label="Continue" onClick={onClick} variant="primary" />
      </div>
    </Dialog>
  );
};
