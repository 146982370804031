export const HOME_PAGE_CONVERSION_TEST_EPPO_ID = 'web-2173-home-page-conversion-test';
export const HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES = {
  ON: 'v1',
  OFF: 'off',
};

export const SFS_HOME_SEARCH_TEST_EPPO_ID = 'web-2403-sfs-home-search-test';
export const SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES = {
  ON: 'v1',
  OFF: 'off',
};
