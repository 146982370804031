import { init } from '@eppo/js-client-sdk';
import { useSmallToMediaQuery, useMediumToMediaQuery } from '@madpaws/design-system';
import Cookies from 'js-cookie';
import { createContext, useEffect, useMemo, useState } from 'react';

import { SEGMENT_ANONYMOUS_ID_COOKIE_NAME } from '~/common/constants/user';

import { useUserDetailsContext } from '../UserDetailsProvider/UserDetailsProvider';
import { trackEvent } from '../analytics/analytics';
import { EPPO_RANDOMISED_ASSIGNMENT_EVENT_NAME } from '../analytics/constants';

import type { ReactElement } from 'react';

const NEXT_PUBLIC_EPPO_API_KEY = process.env.NEXT_PUBLIC_EPPO_API_KEY as string;
const IS_LHCI_TESTING = process.env.LHCI_TESTING;

interface IEppoRandomizationProvider {
  children: ReactElement;
}

export const EppoContext = createContext({
  isInitialized: false,
  isError: false,
  subjectAttributes: {},
  subjectKey: '',
});

export const EppoProvider = ({ children }: IEppoRandomizationProvider): ReactElement | null => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isEppoReady, setIsEppoReady] = useState(false);
  const [segmentIsReady, setSegmentIsReady] = useState(false);
  const [isError, setIsError] = useState(false);
  const { user } = useUserDetailsContext();
  const isSmallToViewport = useSmallToMediaQuery();
  const isMediumToViewport = useMediumToMediaQuery();

  /**
   * This ensures segment anonymous id is available before initializing Eppo
   * Check if the segment anonymous id is already present in the cookies
   * If it is, set the segmentIsReady to true and return
   * If not, check every 100ms for 10 times if the id is present in the cookies
   * If not present after 10 attempts, set segmentIsReady to true and return
   * Because we want to initialise eppo even without the segment anonymous id
   */
  useEffect(() => {
    if (!!Cookies.get(SEGMENT_ANONYMOUS_ID_COOKIE_NAME) || IS_LHCI_TESTING) {
      setSegmentIsReady(true);
      return;
    }

    let getSegmentattempts = 0;
    const getSegmentMaxAttempts = 12;
    const getSegmentInterval = setInterval(() => {
      getSegmentattempts++;
      const segmentAnonymousIdFromCookies = !!Cookies.get(SEGMENT_ANONYMOUS_ID_COOKIE_NAME);

      if (segmentAnonymousIdFromCookies) {
        setSegmentIsReady(true);
        clearTimeout(getSegmentInterval);
      } else if (getSegmentattempts >= getSegmentMaxAttempts) {
        setSegmentIsReady(true);
        clearInterval(getSegmentInterval); // Stop after 10 attempts
      }
    }, 100);

    return () => clearTimeout(getSegmentInterval);
  }, [setSegmentIsReady]);

  /**
   * Initialize Eppo with the provided API key
   * If the initialization is successful, set isEppoReady to true
   */
  useEffect(() => {
    init({
      apiKey: NEXT_PUBLIC_EPPO_API_KEY,
      assignmentLogger: {
        logAssignment(assignment) {
          trackEvent(EPPO_RANDOMISED_ASSIGNMENT_EVENT_NAME, { ...assignment });
        },
      },
    })
      .then(() => setIsEppoReady(true))
      .catch(() => {
        setIsError(true);
        console.warn('Failed to initialize Eppo');
      });
  }, []);

  /**
   * If Eppo and Segment are ready, set isInitialized to true
   * This will allow Eppo to get the assignment for the user
   */
  useEffect(() => {
    if (isEppoReady && segmentIsReady) {
      setIsInitialized(true);
    }
  }, [isEppoReady, segmentIsReady]);

  const subjectKey = Cookies.get(SEGMENT_ANONYMOUS_ID_COOKIE_NAME) as string;

  /**
   * Determine the device type based on the viewport size
   */
  const deviceType = useMemo(() => {
    if (isSmallToViewport) {
      return 'mobile_web';
    }
    if (isMediumToViewport) {
      return 'tablet_web';
    }

    return 'desktop_web';
  }, [isMediumToViewport, isSmallToViewport]);

  const subjectAttributes = useMemo(
    () => ({
      city: user?.city || '',
      isSitter: user?.isSitter !== undefined ? user.isSitter : 'unset',
      state: user?.state || '',
      postCode: user?.postcode || '',
      userId: user?.id || '',
      deviceType,
    }),
    [user, deviceType]
  );

  return (
    <EppoContext.Provider value={{ isInitialized, isError, subjectAttributes, subjectKey }}>
      {children}
    </EppoContext.Provider>
  );
};
