import { useMutation } from '@apollo/client';
import { ButtonLink, Dialog, Heading } from '@madpaws/design-system';
import { Button } from '@madpaws/design-system';
import { useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { FORM_IDS } from '~/common/constants/app';
import {
  ACCESS_TOKEN_COOKIE_NAME,
  MD_AUTH_LOCAL_STORAGE_KEY,
  QANTAS_TOKEN_COOKIE_NAME,
  SIGN_IN_ROUTE,
} from '~/common/constants/auth';
import { GoogleSignUpDialog } from '~/components/GoogleOAuthProvider/SignUpDialog/GoogleSignUpDialog';
import { GOOGLE_LOGIN_GRANT_TYPE } from '~/components/GoogleOAuthProvider/constants';
import { SIGN_IN_WITH_GOOGLE } from '~/components/GoogleOAuthProvider/queries';
import { useUserLoggedInContext } from '~/components/UserAuthProvider/UserAuthProvider';
import { trackEvent } from '~/components/analytics/analytics';
import { USER_CONTINUE_LOGIN_CLICK, USER_LOG_IN_COMPLETED } from '~/components/analytics/constants';
import { DOM_CONTAINER_ID } from '~/components/constants';
import { RestApi } from '~/services/restApi';
import { removeLocalStorage, setLocalStorage } from '~/utils/localStorage';
import { sentryException } from '~/utils/sentryHelpers';

import styles from './AuthDialog.module.css';
import { EmailSignUpForm } from './EmailSignUpForm';

import type { CodeResponse } from '@react-oauth/google';
import type { ReactElement } from 'react';
import type { GoogleLoginArgs } from '~/api/graphql/googleSignIn/GoogleSignInApi/types';
import type { LoginResponse } from '~/api/graphql/googleSignIn/typeDefs';
import type { GoogleSignUpProps } from '~/components/GoogleOAuthProvider/GoogleOneTapLoginProvider';

const { publicRuntimeConfig } = getConfig();

export type Props = {
  handleDialogClose: () => void;
  isOpen: boolean;
  onLoginSuccessRedirectUrl?: string;
  title: string;
};

export const AuthDialog = ({
  isOpen,
  handleDialogClose,
  title,
  onLoginSuccessRedirectUrl,
}: Props): ReactElement => {
  const { setIsUserLoggedIn } = useUserLoggedInContext();

  const router = useRouter();

  const [isSignUpDialogVisible, setIsSignUpDialogVisible] = useState<boolean>(false);
  const [loginResponse, setLoginResponse] = useState<LoginResponse | null>(null);
  const [termsFormError, setTermsFormError] = useState<string | null>(null);
  const [isTermsFormSubmitting, setIsTermsFormSubmitting] = useState<boolean>(false);

  const onGoogleSignInFailed = (error: Error): void => {
    setTermsFormError(error.message);
    setIsTermsFormSubmitting(false);
    sentryException(error, 'signInWithGoogle onError');
  };

  const postLoginAction = (): void => {
    setIsSignUpDialogVisible(false);
    setIsUserLoggedIn(true);

    if (onLoginSuccessRedirectUrl) {
      setTimeout(() => {
        router.push(onLoginSuccessRedirectUrl);
      }, 500);
    }
  };

  const onGoogleSignInCompleted = (data: { signInWithGoogle: LoginResponse }): void => {
    const { signInWithGoogle: auth } = data;
    const { userNeedsToAcceptTerms, isUserPhoneNumberVerified } = auth;
    // show the google sign up dialog if user needs to accept TnCs
    if (userNeedsToAcceptTerms) {
      setTermsFormError(null);
      setIsSignUpDialogVisible(true);
      setLoginResponse(auth);

      return;
    }

    // or show the google sign up dialog if user has unverified phone number
    if (isUserPhoneNumberVerified === false) {
      setIsSignUpDialogVisible(true);
      setLoginResponse(auth);
      Cookies.set(ACCESS_TOKEN_COOKIE_NAME, JSON.stringify({ tokens: auth }) ?? '');
      setLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY, { ...auth });
      RestApi.setAuthorizationHeader({
        authorization: `Bearer ${auth.access_token}`,
      });

      return;
    }

    // otherwise complete the login and no need to show the dialog
    Cookies.set(ACCESS_TOKEN_COOKIE_NAME, JSON.stringify({ tokens: auth }) ?? '');
    setLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY, { ...auth });
    RestApi.setAuthorizationHeader({
      authorization: `Bearer ${auth.access_token}`,
    });
    setIsTermsFormSubmitting(false);
    setTermsFormError(null);

    postLoginAction();

    trackEvent(USER_LOG_IN_COMPLETED, {
      grant_type: GOOGLE_LOGIN_GRANT_TYPE,
      email: auth.email,
    });
  };

  const [signInWithGoogle] = useMutation<{ signInWithGoogle: LoginResponse }>(SIGN_IN_WITH_GOOGLE, {
    onCompleted: onGoogleSignInCompleted,
    onError: onGoogleSignInFailed,
  });

  const getGoogleLoginArgs = async (
    googleToken: string,
    signUpProps?: GoogleSignUpProps
  ): Promise<GoogleLoginArgs> => {
    const {
      route: routerRoute,
      query: { refSource, referralCode },
    } = router;
    const qantasToken = Cookies.get(QANTAS_TOKEN_COOKIE_NAME) ?? '';

    let googleLoginArgs: GoogleLoginArgs = {
      client_id: Number(process.env.NEXT_PUBLIC_CLIENT_ID ?? 0),
      client_secret: process.env.NEXT_PUBLIC_CLIENT_SECRET ?? '',
      google_token: googleToken,
      grant_type: GOOGLE_LOGIN_GRANT_TYPE,
      newUserAcceptedTerms: false,
      qantasSrc: !!qantasToken,
      qantasToken,
      one_tap: routerRoute,
      receiveSpecialOffers: false,
      refSource: (refSource as string) ?? null,
      referralCode: (referralCode as string) ?? null,
    };

    // await to check if 2FA is enabled
    // TODO (WEB-2333): Move to Eppo
    // await getTreatments();
    // const isTwoFactorAuthEnabled =
    //   treatments[TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID] ===
    //   TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID_VALUES.ON;
    const isTwoFactorAuthEnabled = false;

    if (isTwoFactorAuthEnabled) {
      googleLoginArgs = {
        ...googleLoginArgs,
        hasLimitedScope: true,
      };
    }

    if (signUpProps) {
      const { firstName, lastName, newUserAcceptedTerms, receiveSpecialOffers } = signUpProps;

      googleLoginArgs = {
        ...googleLoginArgs,
        first_name: firstName,
        last_name: lastName,
        newUserAcceptedTerms: newUserAcceptedTerms ?? false,
        receiveSpecialOffers: receiveSpecialOffers ?? false,
      };
    }

    return googleLoginArgs;
  };

  const submitGoogleSignIn = async (
    googleToken: string,
    signUpProps?: GoogleSignUpProps
  ): Promise<void> => {
    handleDialogClose();

    const googleLoginArgs: GoogleLoginArgs = await getGoogleLoginArgs(googleToken, signUpProps);

    try {
      await signInWithGoogle({ variables: { googleLoginArgs } });
    } catch (error) {
      sentryException(
        error,
        `signInWithGoogle mutation failed, googleLoginArgs: ${JSON.stringify(
          googleLoginArgs,
          null,
          2
        )}`
      );
    }
  };

  const loginWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    scope: 'email',
    onSuccess: (response: CodeResponse) => submitGoogleSignIn(response.code),
    onError: (error) => console.log(error.error_description),
  });

  const handleGoogleLogin = (): void => {
    loginWithGoogle();
    trackEvent(USER_CONTINUE_LOGIN_CLICK, { grant_type: GOOGLE_LOGIN_GRANT_TYPE });
  };

  const onGoogleSignUpDialogClose = (): void => {
    removeLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY);
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    setIsUserLoggedIn(false);
    setIsSignUpDialogVisible(false);
    setTermsFormError(null);
  };

  const onGoogleSignUpFormSubmit = (data: GoogleSignUpProps): void => {
    setIsTermsFormSubmitting(true);
    setTermsFormError(null);
    submitGoogleSignIn(data.googleToken, data);
  };

  const handleLogIn = (): void => {
    router.push(`${SIGN_IN_ROUTE}?redirectUrl=${onLoginSuccessRedirectUrl ?? router.asPath}`);
  };

  return (
    <>
      <Dialog
        domContainerId={DOM_CONTAINER_ID}
        formId={FORM_IDS.SIGN_UP_FORM}
        heading="Create account"
        isOpen={isOpen}
        onRequestClose={handleDialogClose}
      >
        <div className={styles.dialogContainer}>
          <Heading alignment="centerAlign" level={4} size="small2x">
            {title || `Create a free account or log in`}
          </Heading>

          <Button
            iconLeading={
              <img
                alt="google icon"
                src={`${publicRuntimeConfig.staticPath}/icons/sign-in/iconGoogle.svg`}
              />
            }
            isFullBleed
            label="Continue with Google"
            onClick={handleGoogleLogin}
            variant="secondary"
          />

          <div className={styles.divider}>
            <span className={styles.hr} />
            <p>or sign up with email</p>
            <span className={styles.hr} />
          </div>

          <EmailSignUpForm onLoginSuccessRedirectUrl={onLoginSuccessRedirectUrl} />

          <div className={styles.login}>
            <p>Have a Mad Paws account? </p>

            <ButtonLink onClick={handleLogIn}>Log in</ButtonLink>
          </div>
        </div>
      </Dialog>

      <GoogleSignUpDialog
        data={loginResponse}
        isOpen={isSignUpDialogVisible}
        isTermsFormSubmitting={isTermsFormSubmitting}
        onDialogClose={onGoogleSignUpDialogClose}
        onSubmit={onGoogleSignUpFormSubmit}
        onSuccessLogin={postLoginAction}
        termsFormError={termsFormError}
      />
    </>
  );
};
