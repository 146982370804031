import { useMutation } from '@apollo/client';
import {
  CommonPlatformHeader as DesignSystemPlatformHeader,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import {
  ACCESS_TOKEN_COOKIE_NAME,
  MD_AUTH_LOCAL_STORAGE_KEY,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
} from '~/common/constants/auth';
import { transformProfileUrl } from '~/common/utils/sitters';
import {
  HOME_PAGE_CONVERSION_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
  HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';
import { useUserLoggedInContext } from '~/components/UserAuthProvider/UserAuthProvider';
import { useUserDetailsContext } from '~/components/UserDetailsProvider/UserDetailsProvider';
import { SourceType, handleNavigationItemClickedEvent } from '~/components/analytics/utils';
import { DOM_CONTAINER_ID } from '~/components/constants';
import { removeLocalStorage } from '~/utils/localStorage';

import { LOG_OUT_USER } from './queries';
import {
  getLeftNavigationLinks,
  getIconLinks,
  getRightNavigationLinks,
  getPrimaryMenuLinks,
  getSecondaryMenuLinks,
  getSecondaryNavigationLinks,
  getSignUpLoginLink,
} from './utils';

import type { CommonPlatformHeaderUserDetails } from '@madpaws/design-system';
import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

const PlatformHeader = (): ReactElement => {
  const router = useRouter();
  const isMediumFromViewport = useMediumFromMediaQuery();
  const [userDetails, setUserDetails] = useState<CommonPlatformHeaderUserDetails | undefined>(
    undefined
  );
  const { isUserLoggedIn, setIsUserLoggedIn } = useUserLoggedInContext();
  const { isUserSitter, user, numberOfUnreadMessages } = useUserDetailsContext();

  const isHomePageComponentChangesEnabled =
    useEppoStringAssignment(HOME_PAGE_CONVERSION_TEST_EPPO_ID) ===
    HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES.ON;

  const isSfsHomeSearchTestEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;

  useEffect(() => {
    if (user) {
      const { isSitter, avatar, fullName, city, state, rewriteUrl } = user;
      const sitterUrl = transformProfileUrl(city, rewriteUrl, state);

      setUserDetails({
        avatar: avatar,
        name: fullName,
        sitterUrl: isSitter ? sitterUrl : undefined,
        onClick: () => {
          handleNavigationItemClickedEvent(
            sitterUrl,
            'View public profile',
            isMediumFromViewport ? SourceType.HamburgerMenuDesktop : SourceType.HamburgerMenuMobile,
            isSitter
          );
        },
      });
    }
  }, [user, isMediumFromViewport]);

  const [logOutUser] = useMutation<{ logOut: boolean }>(LOG_OUT_USER, {
    onCompleted: ({ logOut: loggedOut }) => {
      if (loggedOut) {
        removeLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY);
        Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
        setIsUserLoggedIn(false);
        window.location.reload();
      }
    },
  });

  const onLogOutClick = (): void => {
    logOutUser();
    handleNavigationItemClickedEvent(
      '',
      'Log out',
      isMediumFromViewport ? SourceType.HamburgerMenuDesktop : SourceType.HamburgerMenuMobile,
      isUserSitter
    );
  };

  const iconLinks = getIconLinks(
    isUserLoggedIn ?? false,
    isUserSitter,
    numberOfUnreadMessages,
    isSfsHomeSearchTestEnabled
  );
  const leftNavigationLinks = getLeftNavigationLinks(
    isUserSitter,
    router.pathname,
    isHomePageComponentChangesEnabled
  );
  const rightNavigationLinks = getRightNavigationLinks(isUserLoggedIn ?? false, isUserSitter);
  const primaryMenuLinks = getPrimaryMenuLinks(
    isUserLoggedIn ?? false,
    isUserSitter,
    numberOfUnreadMessages,
    isMediumFromViewport ? SourceType.HamburgerMenuDesktop : SourceType.HamburgerMenuMobile,
    isSfsHomeSearchTestEnabled
  );
  const secondaryMenuLinks = getSecondaryMenuLinks(
    isUserLoggedIn ?? false,
    isUserSitter,
    isMediumFromViewport ? SourceType.HamburgerMenuDesktop : SourceType.HamburgerMenuMobile,
    isHomePageComponentChangesEnabled
  );
  const secondaryNavigationLinks = getSecondaryNavigationLinks(
    isUserLoggedIn ?? false,
    isUserSitter,
    router.pathname,
    isSfsHomeSearchTestEnabled
  );
  const logInLink = getSignUpLoginLink(
    isUserLoggedIn ?? false,
    isUserSitter,
    SIGN_IN_ROUTE,
    'Log in',
    isMediumFromViewport ? SourceType.HeaderPrimary : SourceType.HamburgerMenuMobile
  );
  const signUpLink = getSignUpLoginLink(
    isUserLoggedIn ?? false,
    isUserSitter,
    SIGN_UP_ROUTE,
    'Sign up',
    isMediumFromViewport ? SourceType.HeaderPrimary : SourceType.HamburgerMenuMobile
  );

  return (
    <DesignSystemPlatformHeader
      domContainerId={DOM_CONTAINER_ID}
      iconLinks={iconLinks}
      leftNavigationLinks={leftNavigationLinks}
      logInLink={logInLink}
      logoFolderPath={`${publicRuntimeConfig.staticPath}/images`}
      onLogOutClick={onLogOutClick}
      primaryMenuLinks={primaryMenuLinks}
      rightNavigationLinks={rightNavigationLinks}
      secondaryMenuLinks={secondaryMenuLinks}
      secondaryNavigationLinks={secondaryNavigationLinks}
      signUpLink={signUpLink}
      userDetails={userDetails}
    />
  );
};

export { PlatformHeader };
